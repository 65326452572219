'use strict'

# UserAgent
_ua = do ->
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and not ltIE6
  IE8 = IE and ltIE8 and not ltIE7 and not ltIE6
  IE9 = IE and ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  IE10 = IE and not ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  Webkit = not document.uniqueID and not window.opera and not window.sidebar and not window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }

# URL
url = do ->
  href = location.href.split('/')

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/pc\/[^\/]+\/.*$/

  for val , i in href
    if val is '' or i is href.length - 1 and val.indexOf('.')
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 3

    for val , i in href
      if val is 'pc' and href[i-1] is 'work'
        length = 4

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j is 0
      path += '//'

    else
      path += '/'

  return path

# スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing,{
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
  }
)

$(()->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  # フッター高さ取得
  footerHeight =
    ()->
      add = 0
      height = $('.l-footer').height()
      outerHeight = $('.l-footer').outerHeight(true)
      diff = height - outerHeight
      if diff > 0
        $('.l-content').css('padding-bottom',height+add)
        $('.l-footer').css('height',height)
      else
        $('.l-content').css('padding-bottom',outerHeight+add)
        $('.l-footer').css('height',height)
      return

  footerHeight()

  $(window).on('load resize',
    ->
      footerHeight()
  )

  $('a[href^="#"]'+'a[href!="#"]').on('click.smoothScroll'
    (e)->
      $(
        if (navigator.userAgent.indexOf('Opera') isnt -1)
        then (
          if document.compatMode is 'BackCompat'
          then 'body'
          else 'html'
        )
        else 'html,body'
      ).animate({
        scrollTop:$($(this).attr('href')).offset().top - 20
      },{
        easing:'easeInOutCirc',
        duration:1000
      })

      e.preventDefault()
      return
  )

  fixedContents =
    ($target)->
      $sub = $target.find('.l-sub')
      $buttonContents = $('.m-points')
      mainWrapperHeight = $('.l-main').height() + $buttonContents.outerHeight(true)
      subWrapperHeight = $sub.outerHeight(true)
      footerWrapperHeight = $('.l-footer').outerHeight()

      if subWrapperHeight < mainWrapperHeight
        if $class.indexOf('home') isnt -1
          $('.m-latest-list').css('padding-bottom','0')
        else
          $('.js-fix-contents-main').css('padding-bottom', $buttonContents.outerHeight(true))
        # $('.l-sub').css('min-height', $('.js-fix-contents-main').outerHeight(true) +  + $buttonContents.outerHeight(true))
        $('.l-sub').css('height', $('.js-fix-contents-main').outerHeight(true))

        mainContentsHeight = $('.js-fix-contents-main').outerHeight(true)
        subContentsHeight = $('.js-fix-contents-sub').outerHeight(true)
        windowHeight = $(window).height()
        # 30 = メニュー下マージン
        # adjustMargin = $('.gnavi').outerHeight() + 30
        adjustMargin = 20

        $fixContents = $('.s-fix-contents')
        fixContentsHeight = $('.s-fix-contents').outerHeight(true)
        fixContentsPos = $fixContents.offset().top - adjustMargin

        fixFlg = false
        bottomFlg = false

        $(window).on('load scroll resize',
          ()->
            # メインコンテンツの高さが変わった時時再度高さ適応
            currentSubHeight = $sub.outerHeight(true)
            currentMainContentsHeight = $('.js-fix-contents-main').outerHeight(true)

            if currentMainContentsHeight > mainContentsHeight
              $sub.css('height', currentMainContentsHeight)

            currentSubContentsHeight = $('.js-fix-contents-sub').outerHeight(true)

            # メニュー開閉等で位置が変わった時再計算
            currentFixContentsPos = $('.s-fix-contents').offset().top - adjustMargin

            if $('.s-fix-contents').not('.is-fixed,.is-bottom').length isnt 0

              if currentFixContentsPos > fixContentsPos
                fixContentsPos = currentFixContentsPos

            # サブコンテンツの方が高さが低い時のみ実行
            if currentSubContentsHeight < currentMainContentsHeight
              scrollPos = $(window).scrollTop()
              fixContentsBottomPos = $('.l-container').height() - (scrollPos + windowHeight) - $('.l-footer').outerHeight() - ((adjustMargin + fixContentsHeight) - windowHeight)

              if scrollPos > fixContentsPos
                if not fixFlg and not bottomFlg
                  $fixContents.addClass('is-fixed')
                  fixFlg = true
              else
                $fixContents.removeClass('is-fixed')
                if fixFlg
                  fixFlg = false

              if fixContentsBottomPos < 0
                $fixContents.removeClass('is-fixed')
                if fixFlg
                  fixFlg = false

                  if not bottomFlg
                    $fixContents.addClass('is-bottom')
                    bottomFlg = true
              else
                if bottomFlg
                  $fixContents.removeClass('is-bottom').addClass('is-fixed')
                  bottomFlg = false
        )
      else
        $('.js-fix-contents-main').css('height', $('.l-sub').outerHeight())

  fixedContents($('.l-container'))

  $('.js-parallax-bg').enllax()
  
  $('.js-changeBg-button').on('mouseenter'
    ()->
      $(this).closest('.js-changeBg').removeClass('point1')
      $(this).closest('.js-changeBg').removeClass('point2')
      $(this).closest('.js-changeBg').removeClass('point3')
      $(this).closest('.js-changeBg').addClass($(this).attr('data-bg'))
  )
  
  $('.js-changeBg-button').on('mouseleave'
    ()->
      $(this).closest('.js-changeBg').removeClass('point1')
      $(this).closest('.js-changeBg').removeClass('point2')
      $(this).closest('.js-changeBg').removeClass('point3')
  )
  
)
